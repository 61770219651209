import React, { Component, useEffect } from 'react';
import Navbar from "../components/Navbar/Navbar";
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import Selector from "../components/LanguageSelector/Selector.js";
import '../i18n/i18n';
import colours from "../style/colours";

const gradientStyle = `linear-gradient(135deg, ${colours['primary']} 0%, ${colours['secondary']} 100%)`

const skills = {
    languages: ["JavaScript", "Python", "Java", "Go", "C/C#", "HTML/CSS", "SQL", "Bash"],
    frameworks: ["ReactJS/React Native", "Node.js", "Vue.js", "Angular.js", "Spring/Spring Boot", "Redux", "Auth0", "JUnit", "LangChain", "JavaFX", "Socket.io", "Mapbox"],
    tools: ["AWS (S3, EC2, DynamoDB, Lambda, SQS)", "Docker", "Redis", "ScyllaDB", "MySQL", "Git/Bitbucket/GitLab", "Ansible", "JIRA", "Alembic", "Figma", "Azure DevOps", "Terraform"]
}

const Separator = (props) => (
    <div style={{ margin: 40, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', }}>
        <hr style={{ borderColor: 'rgba(255, 255, 255, 0.05)', width: '26vw', marginRight: 40 }} />
        <div 
            style={{
                ...styles.headerColor, 
                fontWeight: 500, 
                fontSize: 40, 
                marginBottom: 0, 
                marginTop: 0, 
                width: 240, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
            }}
        >
            {props.sectionHeader}
        </div>
        <hr style={{ borderColor: 'rgba(255, 255, 255, 0.05)', width: '26vw', marginLeft: 40 }} />
    </div>
)

const Skill = (props) => {
    return (
        <div
            style={{ 
                background: 'rgba(255, 255, 255, 0.3)', 
                color: 'white', 
                padding: 10, 
                borderRadius: 14, 
                margin: 2, 
                display: 'inline-block' 
            }}
        >
            {props.skill}
        </div>
    )
}

const HeaderSection = (props) => {
    return (
        <div 
            style={{ 
                width: props?.isDesktop ? '30%' : '100%', 
                height: 200, 
                borderRadius: 10,
                background: 'rgba(255, 255, 255, 0.1)',
                overflowY: 'scroll',
                marginBottom: props?.isDesktop ? 0 : 20,
            }}
        >
            <div 
                style={{ 
                    height: 40, 
                    fontWeight: 500,
                    letterSpacing: -0.2,
                    width: '100%', 
                    background: 'rgba(255, 255, 255, 0.1)', 
                    borderRadius: '10px 10px 0 0', 
                    marginBottom: 15, 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 15,
                    color: 'rgba(255, 255, 255, 1)'
                }}
            >
                {props?.title}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {props.body}
            </div>
        </div>
    )
}

const JobSection = (props) => {
    return (
        <div style={styles.workSection}>
            {props?.noHeader ? <div style={styles.circleSmaller} /> : <div style={styles.circle} />}
            <div 
                style={{ 
                    position: 'absolute',
                    top: props?.current ? 7 : (!props?.noHeader ? 0 : -5),
                    fontSize: 10,
                    textAlign: 'right',
                    left: -80, 
                    color: 'rgba(255, 255, 255, 0.7)', 
                }}
            >
                {props?.current && <div style={{ fontSize: 14, fontWeight: 500, }}>Current</div>}
                {!props?.current && <div>{props?.startMonth} - {props?.endMonth}</div>}
                {!props?.current && <div style={{ fontSize: 14, }}>{props?.year}</div>}
            </div>
            {!props?.noHeader && 
            <Link to={props?.websiteLink}>
                <h3 
                    style={{ 
                        fontSize: 29, 
                        fontWeight: 600,
                        backgroundImage: gradientStyle, 
                        "-webkit-background-clip": "text", 
                        "-webkit-text-fill-color": "transparent", 
                        marginTop: 20 
                    }}
                > 
                   {props?.companyName}
                </h3>
            </Link>}
            <h3 style={{ fontSize: 18, fontWeight: 500, color: 'rgba(255, 255, 255, 0.9)',  }}>{props?.role}</h3>
            <ul style={{ fontSize: props?.isDesktop ? 18 : 15, width: '80%' }}>
                {(props?.paragraphs ?? []).map((paragraph) => <li>{paragraph}</li> )}
            </ul>
        </div>
    )
}

const VolunteerSection = (props) => {
    return (
        <div style={{ ...styles.workSection, paddingLeft: 20, }}>
            <Link to={props?.websiteLink}>
                <h3 
                    style={{ 
                        fontSize: 29, 
                        fontWeight: 600,
                        backgroundImage: gradientStyle, 
                        "-webkit-background-clip": "text", 
                        "-webkit-text-fill-color": "transparent", 
                        marginTop: 20 
                    }}
                > 
                   {props?.companyName}
                </h3>
            </Link>
            <h3 style={{ fontSize: 18, fontWeight: 400 }}>{props?.role} · {props?.startMonth} {props?.endMonth ? "- " + props?.endMonth : ""} {props?.year}</h3>
            <ul style={{ fontSize: props?.isDesktop ? 18 : 15, width: '80%' }}>
                {(props?.paragraphs ?? []).map((paragraph) => <li>{paragraph}</li> )}
            </ul>
        </div>
    )
}

const ProjectSection = (props) => {
    return (
        <div style={{ ...styles.workSection, paddingLeft: 20, }}>
            <Link to={props?.websiteLink}>
                <h3 
                    style={{ 
                        fontSize: 29, 
                        fontWeight: 600,
                        backgroundImage: gradientStyle, 
                        "-webkit-background-clip": "text", 
                        "-webkit-text-fill-color": "transparent", 
                        marginTop: 20 
                    }}
                > 
                   {props?.companyName}
                </h3>
            </Link>
            <h3 style={{ fontSize: 18, fontWeight: 400 }}>{props?.startMonth} - {props?.endMonth} {props?.year}</h3>
            <ul style={{ fontSize: props?.isDesktop ? 18 : 15, width: '80%' }}>
                {(props?.paragraphs ?? []).map((paragraph) => <li>{paragraph}</li> )}
            </ul>
        </div>
    )
}

const ResumePage = () => {
    const [isDesktop, setIsDesktop] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState('en');

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
      }, [selectedLanguage]);

    return (

        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Navbar />
            <div id="backgroundBokehDark" />
            {/* <Selector setSelectedLanguage={setSelectedLanguage} /> */}
            <Fade duration={1000} distance="30px">
                <div style={{ width: "80%" }}>
                <div style={{ marginTop: isDesktop ? 80 : 120, }}>
                    {isDesktop && <Separator sectionHeader={'Overview'} />}
                </div>
                <div 
                    style={{ 
                        width: '100%', 
                        flexDirection: isDesktop ? 'row' : 'column',
                        display: 'flex',
                        alignItems: isDesktop ? 'center' : undefined, 
                        justifyContent: isDesktop ? 'space-evenly' : undefined,
                    }}
                >
                    <HeaderSection 
                        isDesktop={isDesktop}
                        title={'Contact Information'}
                        body={(
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                                <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                                    <div style={{ fontSize: 11, color: 'rgba(255, 255, 255, 0.7)', }}>
                                        Email
                                    </div>
                                    <div style={{ fontSize: 13, color: 'rgba(255, 255, 255, 1)', fontWeight: 500, }}>
                                        vivekandath@gmail.com
                                    </div>
                                </div>
                                <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 15, }}>
                                    <div style={{ fontSize: 11, color: 'rgba(255, 255, 255, 0.7)', }}>
                                        Phone
                                    </div>
                                    <div style={{ fontSize: 13, color: 'rgba(255, 255, 255, 1)', fontWeight: 500, }}>
                                        +1 (613) 301-0684
                                    </div>
                                </div>
                                <Link
                                    to="http://vivek.kandathil.ca/files/Resume_VivekKandathil.pdf"
                                    activeStyle={{ color: "red" }}
                                    target="blank"
                                    style={{ background: 'rgba(255, 255, 255, 0.2)', width: '80%', height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14, borderRadius: 10, color: 'white', }}
                                >
                                    <div>
                                        Resume PDF
                                    </div>
                                </Link>
                            </div>
                        )}
                    />
                    <HeaderSection 
                        isDesktop={isDesktop}
                        title={'Summary'}
                        body={(
                            <ul style={{ fontSize: 14, textAlign: 'left', width: '90%', marginLeft: -15, marginTop: 10, }}>
                                <li>{'3+ years of work experience in full-stack development (full-time + 9 internships)'}</li>
                                <li>{'Currently the lead full-stack + mobile developer at LispLogics'}</li>
                            </ul>
                        )}
                    />
                    <HeaderSection 
                        isDesktop={isDesktop}
                        title={'Education'}
                        body={(
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                                <div>
                                    <Link to="https://www.mcgill.ca/">
                                        <img 
                                            src={require("../images/mcgill.png")} 
                                            alt={"McGill University"} 
                                            style={{ 
                                                marginBottom: 6, 
                                                width: isDesktop ? 220 : 160,
                                                marginLeft: -15,
                                            }} 
                                        />
                                    </Link>
                                </div>
                                <h3 style={{ fontSize: 16, fontWeight: 500, marginBottom: 2 }}>
                                    BSc. Software Engineering
                                </h3>
                                <div style={{ fontSize: 13, marginBottom: 20, color: 'rgba(255, 255, 255, 0.6)' }}>
                                    {t('resume.dates.september')} 2019 - {t('resume.dates.april')} 2025
                                </div>
                            </div>
                        )}
                    />
                </div>
                <Separator sectionHeader={t('resume.employment')} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", }}>
                    <div style={{ alignItems: 'center', justifyContent: 'center', margin: '0 40px 0 160px', textAlign: 'left' }}>
                        <div className='employment-history-container' style={styles.workHistoryContainer}>
                            <JobSection 
                                websiteLink="https://lisplogics.com/en" 
                                companyName="LispLogics"
                                current
                                role={t('roles.fullstackDeveloper')}
                                startMonth={t('resume.dates.august')}
                                paragraphs={[
                                    t('resume.lisplogics.p1'), 
                                    t('resume.lisplogics.p2'), 
                                    t('resume.lisplogics.p3'), 
                                ]}
                            />
                            <JobSection 
                                noHeader
                                role={t('roles.softwareDeveloperIntern')}
                                startMonth={t('resume.dates.january')}
                                endMonth={t('resume.dates.august')}
                                year="2024"
                                paragraphs={[
                                    t('resume.lisplogics2.p1'), 
                                    t('resume.lisplogics2.p2'), 
                                ]}
                            />
                            <JobSection 
                                websiteLink="https://www.nuance.com/" 
                                companyName="Microsoft"
                                role={t('roles.softwareDeveloperIntern')}
                                startMonth={t('resume.dates.may')}
                                endMonth={t('resume.dates.august')}
                                year="2023"
                                paragraphs={[
                                    t('resume.microsoft.p1'), 
                                    t('resume.microsoft.p2'), 
                                ]}
                            />
                            <JobSection 
                                noHeader
                                role={t('roles.softwareDeveloperIntern')}
                                startMonth={t('resume.dates.may')}
                                endMonth={t('resume.dates.august')}
                                year="2022"
                                paragraphs={[
                                    t('resume.nuance.p1'), 
                                    t('resume.nuance.p2'), 
                                    t('resume.nuance.p3'), 
                                ]}
                            />
                            <JobSection 
                                websiteLink="https://www.hivestack.com/" 
                                companyName="Hivestack"
                                role={t('roles.softwareDeveloperIntern')}
                                startMonth={t('resume.dates.january')}
                                endMonth={t('resume.dates.april')}
                                year="2022"
                                paragraphs={[
                                    t('resume.hivestack.p1'), 
                                    t('resume.hivestack.p2'), 
                                    t('resume.hivestack.p3'), 
                                ]}
                            />
                            <JobSection 
                                websiteLink="https://www.resmed.com/" 
                                companyName="ResMed"
                                role={t('roles.associateSoftwareEngineer')}
                                startMonth={t('resume.dates.may')}
                                endMonth={t('resume.dates.august')}
                                year="2021"
                                paragraphs={[
                                    t('resume.resmed.p1'), 
                                    t('resume.resmed.p2'), 
                                    t('resume.resmed.p3'), 
                                    t('resume.resmed.p4'), 
                                ]}
                            />
                            <JobSection 
                                websiteLink="https://www.nokia.com/" 
                                companyName="Nokia"
                                role={t('roles.softwareDeveloperIntern')}
                                startMonth={t('resume.dates.january')}
                                endMonth={t('resume.dates.april')}
                                year="2021"
                                paragraphs={[
                                    t('resume.nokia1.p1'), 
                                    t('resume.nokia1.p2'), 
                                ]}
                            />
                            <JobSection 
                                noHeader
                                role={t('roles.softwareDeveloperIntern')}
                                startMonth={t('resume.dates.september')}
                                endMonth={t('resume.dates.december')}
                                year="2020"
                                paragraphs={[
                                    t('resume.nokia1.p3'), 
                                    t('resume.nokia1.p4'), 
                                ]}
                            />
                            <JobSection 
                                noHeader
                                role={t('roles.intern')}
                                startMonth={t('resume.dates.july')}
                                endMonth={t('resume.dates.september')}
                                year="2018"
                                paragraphs={[
                                    t('resume.nokia2.p1'), 
                                    t('resume.nokia2.p2'), 
                                    t('resume.nokia2.p3'),
                                ]}
                            />
                            <JobSection 
                                websiteLink="https://www.staples.ca/" 
                                companyName="Staples"
                                role={t('roles.stocker')}
                                startMonth={t('resume.dates.august')}
                                endMonth={t('resume.dates.september')}
                                year="2019"
                                paragraphs={[
                                    t('resume.staples.p1')
                                ]}
                            />
                        </div>
                        <Separator sectionHeader={t('resume.skills')} />
                        <div style={{ display: 'flex', gap: 100, }}>
                            <div>
                                <div style={{ fontSize: 18, fontWeight: 600, color: '#5DC77E', marginBottom: 10, }}>Languages</div>
                                {skills.languages.map((language) => <Skill skill={language} />)}
                            </div>
                            <div>
                                <div style={{ fontSize: 18, fontWeight: 600, color: '#5DC77E', marginBottom: 10, }}>Libraries/Frameworks</div>
                                {skills.frameworks.map((framework) => <Skill skill={framework} />)}
                            </div>
                            <div>
                                <div style={{ fontSize: 18, fontWeight: 600, color: '#5DC77E', marginBottom: 10, }}>Tools</div>
                                {skills.tools.map((tool) => <Skill skill={tool} />)}
                            </div>
                        </div>
                        <Separator sectionHeader={t('button.projects')} />
                        <ProjectSection 
                            websiteLink="https://techcommunity.microsoft.com/blog/studentdeveloperblog/announcing-the-2024-imagine-cup-semifinalists/4066101" 
                            companyName="ParkinSync (Microsoft Imagine Cup Semi-finalist)"
                            startMonth={t('resume.dates.january')}
                            endMonth={t('resume.dates.april')}
                            year="2024"
                            paragraphs={[
                                "Lead a team of 40 McGill students in the design & development of a full-stack software application (ParkinSync) which analyzes signals from a variety of sensors attached to the hand (EMG, accelerometer, etc.), characterizes potential symptoms of Parkinson's disease, and displays the data/analysis in a user-friendly format.",
                                "The ParkinSync software was placed as a semifinalist in the 2024 Microsoft Imagine Cup",
                                "Additionally overseeing the development of an exoskeleton for the hands which assists in suppressing tremors caused by Parkinson's disease",
                            ]}
                        />
                        <ProjectSection 
                            websiteLink="https://mcgillrobotics.com/auv" 
                            companyName="Autonomous Underwater Vehicle (McGill Robotics)"
                            startMonth={t('resume.dates.january')}
                            endMonth={t('resume.dates.september')}
                            year="2024"
                            paragraphs={[
                                "Worked with a team of Engineering students to develop & maintain software for an Autonomous Underwater Vehicle (AUV)",
                                "Developed features for the vision/autonomy and planner software packages in preparation for the RoboSub competition",
                            ]}
                        />
                        <ProjectSection 
                            websiteLink="https://www.mcgill.ca/study/2022-2023/courses/comp-361d1" 
                            companyName="Splendor (Online Multiplayer Game)"
                            startMonth={t('resume.dates.september')}
                            endMonth={t('resume.dates.april')}
                            year="2023"
                            paragraphs={[
                                t('resume.splendor.p1'),
                                t('resume.splendor.p2'),
                                t('resume.splendor.p3'),
                                t('resume.splendor.p4'),
                            ]}
                        />
                        <ProjectSection 
                            websiteLink="https://github.com/SolidGuard/solidguard" 
                            companyName="SolidGuard"
                            startMonth={t('resume.dates.january')}
                            endMonth={t('resume.dates.august')}
                            year="2022"
                            paragraphs={[
                                t('resume.solidguard.p1'),
                                t('resume.solidguard.p2'),
                            ]}
                        />
                        <ProjectSection 
                            websiteLink="https://devpost.com/software/fast-food-haven" 
                            companyName="Fast Food Haven"
                            startMonth={t('resume.dates.january')}
                            year="2020"
                            paragraphs={[
                                t('resume.fastfoodhaven.p1'),
                                t('resume.fastfoodhaven.p2'),
                            ]}
                        />
                        <ProjectSection 
                            websiteLink="https://devpost.com/software/123scheduler" 
                            companyName="123 Scheduler"
                            startMonth={t('resume.dates.november')}
                            year="2022"
                            paragraphs={[
                                t('resume.loadboard.p1'),
                                t('resume.loadboard.p2'),
                                t('resume.loadboard.p3'),
                            ]}
                        />
                        <Link
                            to="/"
                            className="cta-btn cta-btn--hero"
                            style={{ margin: "10px 10px 10px 0" }}
                        >
                            {t('button.viewHome')}
                        </Link>
                        <Link
                            to="https://github.com/vivekandathil"
                            className="cta-btn cta-btn--hero"
                        >
                            {t('button.github')}
                        </Link>
                        <Separator sectionHeader={t('resume.volunteering')} />
                        <VolunteerSection 
                            websiteLink="" 
                            companyName={t('resume.mchacks.n')}
                            role={t('resume.mchacks.t')}
                            startMonth={t('resume.dates.january') + " 2023"}
                            endMonth={t('resume.dates.january') + " 2024"}
                            paragraphs={[
                                t('resume.mchacks.p1'), 
                                t('resume.mchacks.p2'), 
                            ]}
                        />
                        <VolunteerSection 
                            websiteLink="" 
                            companyName={t('resume.ottawa.n')}
                            role={t('resume.ottawa.t')}
                            startMonth={t('resume.dates.september') + " 2018"}
                            paragraphs={[
                                t('resume.ottawa.p1'), 
                                t('resume.ottawa.p2'), 
                            ]}
                        />
                        <VolunteerSection 
                            websiteLink="" 
                            companyName={"McGill Tech Fair"}
                            role={"New Residence Hall"}
                            startMonth={t('resume.dates.september') + " 2019"}
                            paragraphs={[
                                t('resume.techfair')
                            ]}
                        />
                        <VolunteerSection 
                            websiteLink="" 
                            companyName={t('resume.ferrari.t')}
                            role={"Ottawa"}
                            startMonth={t('resume.dates.june') + " 2016-2018"}
                            paragraphs={[
                                t('resume.ferrari.p1')
                            ]}
                        />
                        <VolunteerSection 
                            websiteLink="" 
                            companyName={t('resume.philippines.t')}
                            role={t('resume.philippines.h')}
                            startMonth={t('resume.dates.june') + " 2017"}
                            paragraphs={[
                                t('resume.philippines.p1'),
                                t('resume.philippines.p2')
                            ]}
                        />
                        <div style={{ marginBottom: 50, }} />
                    </div>

                </div>
                </div>
            </Fade >
        </div >

    )
}

export default ResumePage

const styles = {
    headerColor: {
        margin: "30px 0 15px 0",
        letterSpacing: -0.4,
        fontSize: 40,
        fontWeight: 500,
        // textShadow: `#5DC77E 0px 0px 50px`,
    },
    workHistoryContainer: {
        position: 'relative',
        borderLeft: '3px solid rgba(255, 255, 255, 0.05)',
    },
    workSection: {
        position: 'relative',
        paddingLeft: 40,
    },
    circle: {
        position: 'absolute',
        top: 10,
        left: -9,
        border: '2.5px solid #5DC77E',
        width: 14,
        height: 14,
        borderRadius: 28,
        background: '#042323'
    },
    circleSmaller: {
        position: 'absolute',
        top: 6,
        left: -7,
        width: 10,
        height: 10,
        borderRadius: 20,
        background: '#434d4b'
    },
}